/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-20 09:25:42
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 22:33:01
 */
import UserTable from './UserTable'
import UserAdd from './UserAdd'
export default {
  name: 'User',
  components: {
    UserTable,
    UserAdd
  },
  data () {
    return {
      roleList: [],
      agentList: [],
      customList: []
    }
  },
  methods: {
    add () {
      this.$refs.userAdd.show()
    },
    tableSearch (reset) {
      if (reset) {
        this.$refs.userTable.$refs['w-table'].resetList()
      } else {
        this.$refs.userTable.$refs['w-table'].getList()
      }
    }
  },
  async mounted () {
    const roleList = await this.$wPost('/admin/user/list_role.do', {})
    if (roleList) {
      this.roleList = roleList
    }
    const agentList = await this.$wPost('/admin/user/list_agent.do', {})
    if (agentList) {
      this.agentList = agentList
    }
    // const customList = await this.$wPost('/admin/user/list_custom.do', {})
    // if (customList) {
    //   this.customList = customList
    // }
  }
}
